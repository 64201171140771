<template>
	<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no">
	<div class="form-container" id="app">
		<h1 class="elegant-title">スロメモ設定解析ツール</h1>
		<h2 class="summary-title">ツール概要</h2>
		<div>
			<p>スロッターのための設定判別ツールです．現時点でのデータを入力することで統計的に設定を推測します．便宜上，統計的な用語は控えめに使用していることをご了承ください．</p>
			<p>サーバとの通信はないので一度読み込めばオフラインでご使用いただけます．ページのリロードを行うとデータが消失するため，別でメモを取ることをお勧めします．</p>
		</div>

		<br>
		
		<h2 class="summary-title">利用可能な機種一覧</h2>
		<div class="image-container">
			<router-view></router-view> <!-- ルートに基づいて表示されるコンポーネント -->
			画像をタップするとツール画面に遷移します．
			<router-link to="/granbelm" class="nav-link">
				<img class="responsive-image" src="../src/assets/granbelm/title.png" alt="グランベルム">
			</router-link>
			<!-- 他のリンク... -->
		</div>
	</div>
</template>  