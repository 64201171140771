<template>
	<button class="accordion-toggle" :class="{ 'active': isAccordionOpen }" @click="toggleAccordion">
		オプション: 事前確率入力
	</button>

	<div v-if="isAccordionOpen">
		<div>
			<p>
				デフォルト値のままでも構いませんが，ホールの情報や印象から設定の入る確率を仮定することでより事実を反映した設定推測ができます．
				また，以下の確定系が出た場合，その数字未満の設定の事前確率を0にすることで上記の推定に反映できます．
				<ul>
					<li>ディスティニーバトル突入タイミング (2セット目継続時に突入=設定2以上濃厚など，詳しくはお調べください)</li>
				</ul>
			</p>
		</div>
		<div class="prior-probabilities">
			<div v-for="setting in priorProbabilities" :key="setting.id" class="input-group">
				<label :for="'setting-' + setting.id">設定{{ setting.id }}の事前確率 (%)</label>
				<input type="number" :id="'setting-' + setting.id" v-model.number="setting.probability" step="0.01">
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			priorProbabilities: Array
		},
		data() {
			return {
				isAccordionOpen: false // アコーディオンの状態
			}
		},
		methods: {
			toggleAccordion() {
				this.isAccordionOpen = !this.isAccordionOpen;
			}
		},
	};
</script>

<!-- スタイルは必要に応じて追加 -->

<style>
.prior-probabilities {
	display: grid;
	grid-template-columns: repeat(2, 1fr); /* 2列に分割 */
	grid-gap: 30px; /* グリッド間の隙間 */
}

</style>