import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../HomePage.vue'; // ホームページコンポーネントをインポート
import GranbelmPage from '../components/granbelm/GranbelmPage';
// 他のページのインポート...

const routes = [
  { path: '/', component: HomePage },
  { path: '/granbelm', component: GranbelmPage },
  //{ path: '/page2', component: Page2 },
  // 他のルート...
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;