export const data = {
	priorProbabilities: [
		{ id: 1, probability: 16.6 },
		{ id: 2, probability: 16.6 },
		{ id: 3, probability: 16.6 },
		{ id: 4, probability: 16.6 },
		{ id: 5, probability: 16.6 },
		{ id: 6, probability: 16.6 }
	],
	totalSpins: 1000,
	midnightBonuses: 0,
	episodeBonuses: 0,
	czCount: 0, // CZの回数
	secondStageCount: 0, // midnightBonusが2ndステージから始まった回数
	MBChecked: false,
	EBChecked: false,
	CZChecked: false,
	ATCount: 0,
	PBCount: 0,
	PBChecked: false,
	secondStageChecked: false,
	bonusProbabilities: {
		"1": { midnight: 1/315.9, episode: 1/3250.3, cz: 1/298.7 },
		"2": { midnight: 1/312.1, episode: 1/3051.3, cz: 1/291.8 },
		"3": { midnight: 1/297.6, episode: 1/2782.6, cz: 1/278.9 },
		"4": { midnight: 1/276.1, episode: 1/2408.5, cz: 1/258.9 },
		"5": { midnight: 1/264.7, episode: 1/1994.2, cz: 1/245.2 },
		"6": { midnight: 1/256.9, episode: 1/1737.2, cz: 1/235.5 },
	},
	stageProbabilities: {
		"1": { secondStage: 12.5/100 },
		"2": { secondStage: 13.3/100 },
		"3": { secondStage: 14.5/100 },
		"4": { secondStage: 16.4/100 },
		"5": { secondStage: 18.4/100 },
		"6": { secondStage: 20.0/100 },
	},
	atPullbackRates: {
		"1": 1 / 9.5,
		"2": 1 / 8.9,
		"3": 1 / 8.9,
		"4": 1 / 7.3,
		"5": 1 / 7.3,
		"6": 1 / 5.9
	},
	bayesianRatios: {},
	confidenceIntervals: {}, // 信用区間を格納するオブジェクト
	settingSuggestions: {},
	settingSuggestionProbabilities: {
		"1": {
			weakEven: 32.40, weakOdd: 39.60, weakHigh: 6.20, // 弱い示唆
			mediumEven: 5.20, mediumOdd: 8.80, strong246: 0, // 中程度の示唆
			strongEvenHigh: 1.60, strongOddHigh: 4.60, // 強い示唆
			above2: 0, deny3: 0.80, deny5: 0.80, // 特定の設定を否定
			above4: 0, above5: 0, confirm6: 0 // 特定の設定を確定
		},
		"2": {
			weakEven: 36.90, weakOdd: 30.30, weakHigh: 6.20,
			mediumEven: 9.80, mediumOdd: 5.80, strong246: 1.60,
			strongEvenHigh: 4.60, strongOddHigh: 1.60,
			above2: 1.60, deny3: 0.80, deny5: 0.80,
			above4: 0, above5: 0, confirm6: 0
		},
		"3": {
			weakEven: 29.10, weakOdd: 35.70, weakHigh: 7.80,
			mediumEven: 5.20, mediumOdd: 8.80, strong246: 0,
			strongEvenHigh: 2.80, strongOddHigh: 8.20,
			above2: 1.60, deny3: 0, deny5: 0.80,
			above4: 0, above5: 0, confirm6: 0
		},
		"4": {
			weakEven: 32.40, weakOdd: 26.70, weakHigh: 7.90,
			mediumEven: 9.80, mediumOdd: 5.80, strong246: 1.60,
			strongEvenHigh: 8.20, strongOddHigh: 2.80,
			above2: 1.60, deny3: 0.80, deny5: 1.60,
			above4: 0.80, above5: 0, confirm6: 0
		},
		"5": {
			weakEven: 26.10, weakOdd: 31.80, weakHigh: 9.40,
			mediumEven: 5.20, mediumOdd: 8.80, strong246: 0,
			strongEvenHigh: 3.80, strongOddHigh: 10.90,
			above2: 1.60, deny3: 0.80, deny5: 0,
			above4: 0.80, above5: 0.80, confirm6: 0
		},
		// 各設定について同様にデータを記述...
		"6": {
			weakEven: 25.95, weakOdd: 25.95, weakHigh: 9.40,
			mediumEven: 9.80, mediumOdd: 5.80, strong246: 1.60,
			strongEvenHigh: 10.90, strongOddHigh: 3.80,
			above2: 1.60, deny3: 0.80, deny5: 2.00,
			above4: 0.80, above5: 0.80, confirm6: 0.80
		}
	},
	endingSettingSuggestionProbabilities: {
		"1": {
			evenMedium: 22.70,
			oddMedium: 45.30,
			setting246Strong: 0,
			evenStrongHigh: 5.80,
			oddStrongHigh: 16.60,
			setting2orMore: 0,
			setting3Denial: 4.80,
			setting5Denial: 4.80,
			setting4orMore: 0,
			setting5orMore: 0,
			setting6: 0
		},
		"2": {
			evenMedium: 41.60,
			oddMedium: 20.80,
			setting246Strong: 4.80,
			evenStrongHigh: 12.50,
			oddStrongHigh: 4.30,
			setting2orMore: 6.40,
			setting3Denial: 4.80,
			setting5Denial: 4.80,
			setting4orMore: 0,
			setting5orMore: 0,
			setting6: 0
		},
		"3": {
			evenMedium: 22.10,
			oddMedium: 44.30,
			setting246Strong: 0,
			evenStrongHigh: 5.80,
			oddStrongHigh: 16.60,
			setting2orMore: 6.40,
			setting3Denial: 0,
			setting5Denial: 4.80,
			setting4orMore: 0,
			setting5orMore: 0,
			setting6: 0
		},
		"4": {
			evenMedium: 34.70,
			oddMedium: 17.30,
			setting246Strong: 4.80,
			evenStrongHigh: 16.60,
			oddStrongHigh: 5.80,
			setting2orMore: 6.40,
			setting3Denial: 4.80,
			setting5Denial: 4.80,
			setting4orMore: 4.80,
			setting5orMore: 0,
			setting6: 0
		},
		"5": {
			evenMedium: 15.20,
			oddMedium: 30.40,
			setting246Strong: 0,
			evenStrongHigh: 8.30,
			oddStrongHigh: 23.70,
			setting2orMore: 6.40,
			setting3Denial: 4.80,
			setting5Denial: 0,
			setting4orMore: 5.60,
			setting5orMore: 5.60,
			setting6: 0
		},
		"6": {
			evenMedium: 18.40,
			oddMedium: 18.40,
			setting246Strong: 4.80,
			evenStrongHigh: 17.40,
			oddStrongHigh: 6.10,
			setting2orMore: 6.40,
			setting3Denial: 4.80,
			setting5Denial: 4.80,
			setting4orMore: 6.30,
			setting5orMore: 6.30,
			setting6: 6.30
		}
	},
	normalIcons: {},
	cycleNormalProbabilities: {
		"1": {
			blueMoon: 37.90, greenMoon: 1.70, blueDemon: 46.30, greenDemon: 3.30, 
			blueHope: 6.90, greenHope: 3.70, whiteLily: 0.00, crestAnemone: 0.07, 
			snowMoonPlum: 0.07, arcKnightGliss: 0.07, violaCazetta: 0.00, 
			doroCurtain: 0.07, awakeningWhiteLily: 0.00
		},
		"2": {
			blueMoon: 45.70, greenMoon: 3.30, blueDemon: 37.40, greenDemon: 1.70, 
			blueHope: 6.90, greenHope: 4.60, whiteLily: 0.10, crestAnemone: 0.00, 
			snowMoonPlum: 0.07, arcKnightGliss: 0.07, violaCazetta: 0.10, 
			doroCurtain: 0.00, awakeningWhiteLily: 0.00
			},
		"3": {
			blueMoon: 36.30, greenMoon: 1.70, blueDemon: 44.30, greenDemon: 3.30, 
			blueHope: 8.50, greenHope: 5.50, whiteLily: 0.10, crestAnemone: 0.10, 
			snowMoonPlum: 0.00, arcKnightGliss: 0.07, violaCazetta: 0.00, 
			doroCurtain: 0.10, awakeningWhiteLily: 0.00
		},
		"4": {
			blueMoon: 43.70, greenMoon: 3.30, blueDemon: 35.80, greenDemon: 1.70, 
			blueHope: 8.50, greenHope: 6.40, whiteLily: 0.10, crestAnemone: 0.10, 
			snowMoonPlum: 0.10, arcKnightGliss: 0.00, violaCazetta: 0.10, 
			doroCurtain: 0.00, awakeningWhiteLily: 0.10
		},
		"5": {
			blueMoon: 34.70, greenMoon: 1.70, blueDemon: 42.30, greenDemon: 3.30, 
			blueHope: 10.00, greenHope: 7.40, whiteLily: 0.10, crestAnemone: 0.10, 
			snowMoonPlum: 0.10, arcKnightGliss: 0.10, violaCazetta: 0.01, 
			doroCurtain: 0.10, awakeningWhiteLily: 0.10
		},
		"6": {
			blueMoon: 40.50, greenMoon: 3.30, blueDemon: 33.10, greenDemon: 1.70, 
			blueHope: 11.50, greenHope: 9.20, whiteLily: 0.10, crestAnemone: 0.10, 
			snowMoonPlum: 0.10, arcKnightGliss: 0.10, violaCazetta: 0.10, 
			doroCurtain: 0.01, awakeningWhiteLily: 0.10
		},
	},
	zeroFiveIcons: {},
	cycleZeroFiveProbabilities: {
		"1": {
			blueMoon: 36.90, greenMoon: 1.70, blueDemon: 45.10, greenDemon: 3.30,
			blueHope: 7.90, greenHope: 4.20, whiteLily: 0.00, crestAnemone: 0.20,
			snowMoonPlum: 0.20, arcKnightGliss: 0.20, violaCazetta: 0.00,
			doroCurtain: 0.20, awakeningWhiteLily: 0.00, seradoCurtain: 0.00,
			zeaganLone: 0.00
		},
		"2": {
			blueMoon: 42.30, greenMoon: 3.30, blueDemon: 34.60, greenDemon: 1.70,
			blueHope: 7.90, greenHope: 5.30, whiteLily: 3.20, crestAnemone: 0.00,
			snowMoonPlum: 0.20, arcKnightGliss: 0.20, violaCazetta: 1.20,
			doroCurtain: 0.00, awakeningWhiteLily: 0.00, seradoCurtain: 0.00,
			zeaganLone: 0.00
		},
		"3": {
			blueMoon: 33.80, greenMoon: 1.70, blueDemon: 41.30, greenDemon: 3.30,
			blueHope: 9.80, greenHope: 6.30, whiteLily: 3.20, crestAnemone: 0.20,
			snowMoonPlum: 0.00, arcKnightGliss: 0.20, violaCazetta: 0.00,
			doroCurtain: 0.20, awakeningWhiteLily: 0.00, seradoCurtain: 0.00,
			zeaganLone: 0.00
		},
		"4": {
			blueMoon: 39.00, greenMoon: 3.30, blueDemon: 31.90, greenDemon: 1.70,
			blueHope: 9.80, greenHope: 7.40, whiteLily: 3.20, crestAnemone: 0.40,
			snowMoonPlum: 0.40, arcKnightGliss: 0.00, violaCazetta: 2.40,
			doroCurtain: 0.00, awakeningWhiteLily: 0.40, seradoCurtain: 0.00,
			zeaganLone: 0.00
			},
		"5": {
			blueMoon: 31.10, greenMoon: 1.70, blueDemon: 38.00, greenDemon: 3.30,
			blueHope: 11.50, greenHope: 8.50, whiteLily: 3.20, crestAnemone: 0.40,
			snowMoonPlum: 0.40, arcKnightGliss: 0.40, violaCazetta: 0.03,
			doroCurtain: 0.40, awakeningWhiteLily: 0.60, seradoCurtain: 0.40,
			zeaganLone: 0.00
		},
		"6": {
			blueMoon: 34.30, greenMoon: 3.30, blueDemon: 28.00, greenDemon: 1.70,
			blueHope: 13.20, greenHope: 10.60, whiteLily: 3.20, crestAnemone: 0.40,
			snowMoonPlum: 0.40, arcKnightGliss: 0.40, violaCazetta: 2.40,
			doroCurtain: 0.03, awakeningWhiteLily: 0.80, seradoCurtain: 0.60,
			zeaganLone: 0.60
		},
	},
	fiftyFiveIcons: {},
	cycleFiftyFiveProbabilities: {
		"1": {
			blueMoon: 20.90,
			greenMoon: 2.20,
			blueDemon: 41.40,
			greenDemon: 9.00,
			blueHope: 6.90,
			greenHope: 3.70,
			whiteLily: 0.00,
			restAnemone: 6.20,
			snowMoonPlum: 6.20,
			arcKnightGliss: 3.30,
			violaCazettar: 0.00,
			doroCurtain: 0.30,
			awakeningWhiteLily: 0.00,
			seradoCurtain: 0.00,
			zeaganLone: 0.00
		},
		"2": {
			blueMoon: 32.20,
			greenMoon: 9.00,
			blueDemon: 16.20,
			greenDemon: 2.20,
			blueHope: 6.90,
			greenHope: 4.60,
			whiteLily: 8.30,
			restAnemone: 0.00,
			snowMoonPlum: 8.30,
			arcKnightGliss: 4.10,
			violaCazettar: 8.30,
			doroCurtain: 0.00,
			awakeningWhiteLily: 0.00,
			seradoCurtain: 0.00,
			zeaganLone: 0.00
		},
		"3": {
			blueMoon: 18.00,
			greenMoon: 2.20,
			blueDemon: 35.80,
			greenDemon: 9.00,
			blueHope: 8.50,
			greenHope: 5.50,
			whiteLily: 8.30,
			restAnemone: 8.30,
			snowMoonPlum: 0.00,
			arcKnightGliss: 4.10,
			violaCazettar: 0.00,
			doroCurtain: 0.30,
			awakeningWhiteLily: 0.00,
			seradoCurtain: 0.00,
			zeaganLone: 0.00
		},
		"4": {
			blueMoon: 24.40,
			greenMoon: 9.00,
			blueDemon: 12.30,
			greenDemon: 2.20,
			blueHope: 8.50,
			greenHope: 6.40,
			whiteLily: 8.30,
			restAnemone: 8.30,
			snowMoonPlum: 8.30,
			arcKnightGliss: 0.00,
			violaCazettar: 8.30,
			doroCurtain: 0.00,
			awakeningWhiteLily: 4.10,
			seradoCurtain: 0.00,
			zeaganLone: 0.00
		},
		"5": {
			blueMoon: 9.90,
			greenMoon: 2.20,
			blueDemon: 19.70,
			greenDemon: 9.00,
			blueHope: 10.00,
			greenHope: 7.40,
			whiteLily: 8.30,
			restAnemone: 8.30,
			snowMoonPlum: 8.30,
			arcKnightGliss: 6.20,
			violaCazettar: 0.00,
			doroCurtain: 0.30,
			awakeningWhiteLily: 5.20,
			seradoCurtain: 5.20,
			zeaganLone: 0.00
		},
		"6": {
			blueMoon: 34.30,
			greenMoon: 3.30,
			blueDemon: 28.00,
			greenDemon: 1.70,
			blueHope: 13.20,
			greenHope: 10.60,
			whiteLily: 8.30,
			restAnemone: 6.20,
			snowMoonPlum: 6.20,
			arcKnightGliss: 6.20,
			violaCazettar: 8.30,
			doroCurtain: 0.03,
			awakeningWhiteLily: 0.80,
			seradoCurtain: 0.60,
			zeaganLone: 0.60
		}
	},
	ninetyNineIcons: {},
	cycleNinetyNineProbabilities: {
		"1": {
			blueMoon: 31.50,
			greenMoon: 2.20,
			blueDemon: 38.50,
			greenDemon: 12.30,
			blueHope: 6.00,
			greenHope: 5.00,
			whiteLily: 0.00,
			crestAnemone: 1.30,
			snowMoonPlum: 3.10,
			arcKnightGliss: 0.10,
			violaCazetta: 0.00,
			doroCurtain: 0.10,
			awakeningWhiteLily: 0.00,
			seradoCurtain: 0.00,
			zeaganLone: 0.00
		},
		"2": {
			blueMoon: 31.40,
			greenMoon: 12.30,
			blueDemon: 25.70,
			greenDemon: 2.20,
			blueHope: 6.00,
			greenHope: 6.20,
			whiteLily: 5.90,
			crestAnemone: 0.00,
			snowMoonPlum: 3.10,
			arcKnightGliss: 0.80,
			violaCazetta: 6.30,
			doroCurtain: 0.00,
			awakeningWhiteLily: 0.00,
			seradoCurtain: 0.00,
			zeaganLone: 0.00
		},
		"3": {
			blueMoon: 28.50,
			greenMoon: 2.20,
			blueDemon: 34.80,
			greenDemon: 12.30,
			blueHope: 7.50,
			greenHope: 7.50,
			whiteLily: 5.90,
			crestAnemone: 1.30,
			snowMoonPlum: 0.00,
			arcKnightGliss: 0.10,
			violaCazetta: 0.00,
			doroCurtain: 0.10,
			awakeningWhiteLily: 0.00,
			seradoCurtain: 0.00,
			zeaganLone: 0.00
		},
		"4": {
			blueMoon: 27.30,
			greenMoon: 12.30,
			blueDemon: 22.40,
			greenDemon: 2.20,
			blueHope: 7.50,
			greenHope: 8.70,
			whiteLily: 5.90,
			crestAnemone: 1.30,
			snowMoonPlum: 3.10,
			arcKnightGliss: 0.00,
			violaCazetta: 6.30,
			doroCurtain: 0.00,
			awakeningWhiteLily: 3.10,
			seradoCurtain: 0.00,
			zeaganLone: 0.00
		},
		"5": {
			blueMoon: 21.10,
			greenMoon: 2.20,
			blueDemon: 25.80,
			greenDemon: 12.30,
			blueHope: 10.10,
			greenHope: 10.00,
			whiteLily: 5.90,
			crestAnemone: 1.30,
			snowMoonPlum: 3.10,
			arcKnightGliss: 1.70,
			violaCazetta: 0.00,
			doroCurtain: 0.40,
			awakeningWhiteLily: 3.10,
			seradoCurtain: 3.10,
			zeaganLone: 0.00
		},
		"6": {
			blueMoon: 19.40,
			greenMoon: 12.30,
			blueDemon: 15.80,
			greenDemon: 2.20,
			blueHope: 10.10,
			greenHope: 12.50,
			whiteLily: 5.90,
			crestAnemone: 1.30,
			snowMoonPlum: 3.10,
			arcKnightGliss: 1.80,
			violaCazetta: 6.30,
			doroCurtain: 0.00,
			awakeningWhiteLily: 3.10,
			seradoCurtain: 3.10,
			zeaganLone: 3.10
		}
	},
	payouts: {},
	payoutProbabilities: {
		"1": {
			payout222: 0,
			payout333: 0,
			payout456: 0,
			payout555: 0,
			payout666: 0
		},
		"2": {
			payout222: 2.4,
			payout333: 0,
			payout456: 0,
			payout555: 0,
			payout666: 0
		},
		"3": {
			payout222: 2.4,
			payout333: 1.2,
			payout456: 0,
			payout555: 0,
			payout666: 0
		},
		"4": {
			payout222: 3.2,
			payout333: 1.2,
			payout456: 1.2,
			payout555: 0,
			payout666: 0
		},
		"5": {
			payout222: 3.2,
			payout333: 1.6,
			payout456: 1.2,
			payout555: 1.2,
			payout666: 0
		},
		"6": {
			payout222: 4.8,
			payout333: 2.0,
			payout456: 1.6,
			payout555: 1.2,
			payout666: 1.2
		}
	},
	foreseeing: {},
	foreseeingProbabilities: {
		"1": {
			whiteLily: 0.0,
			crestAnemone: 0.0,
			snowMoonPlum: 0.0,
			arcKnightGliss: 0.0,
			violaCazetta: 0.0,
			awakeningWhiteLily: 0.0,
			seradoCurtain: 0.0,
			zeaganLone: 0.0
		},
		"2": {
			whiteLily: 47.5,
			crestAnemone: 0.0,
			snowMoonPlum: 17.5,
			arcKnightGliss: 17.5,
			violaCazetta: 17.5,
			awakeningWhiteLily: 0.0,
			seradoCurtain: 0.0,
			zeaganLone: 0.0
		},
		"3": {
			whiteLily: 45.0,
			crestAnemone: 37.5,
			snowMoonPlum: 0.0,
			arcKnightGliss: 17.5,
			violaCazetta: 0.0,
			awakeningWhiteLily: 0.0,
			seradoCurtain: 0.0,
			zeaganLone: 0.0
		},
		"4": {
			whiteLily: 45.0,
			crestAnemone: 25.0,
			snowMoonPlum: 25.0,
			arcKnightGliss: 0.0,
			violaCazetta: 12.5,
			awakeningWhiteLily: 7.5,
			seradoCurtain: 0.0,
			zeaganLone: 0.0
		},
		"5": {
			whiteLily: 45.0,
			crestAnemone: 20.0,
			snowMoonPlum: 20.0,
			arcKnightGliss: 20.0,
			violaCazetta: 0.0,
			awakeningWhiteLily: 5.0,
			seradoCurtain: 5.0,
			zeaganLone: 0.0
		},
		"6": {
			whiteLily: 45.0,
			crestAnemone: 15.0,
			snowMoonPlum: 17.5,
			arcKnightGliss: 20.0,
			violaCazetta: 12.5,
			awakeningWhiteLily: 5.0,
			seradoCurtain: 5.0,
			zeaganLone: 5.0
		}
	}
};