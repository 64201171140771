<template>
	<div>
		<canvas ref="barChart"></canvas>
	</div>
</template>
  
<script>
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default {
	props: {
		bayesianRatios: Object
	},
	data() {
		return {
			chart: null
		};
	},

	mounted() {
		// データが存在するかを確認
		//console.log('Bayesian Ratios: (Bar)', JSON.parse(JSON.stringify(this.bayesianRatios)));
		this.createChart();
	},
	methods: {
		createChart() {
			const ctx = this.$refs.barChart.getContext('2d');
			this.chart = new Chart(ctx, {
				type: 'bar',
				data: this.getChartData(),
				options: this.getChartOptions(),
			});
		},
		updateChart() {
			//console.log('Bayesian Ratios: (updateChart)', JSON.parse(JSON.stringify(this.bayesianRatios)));
			if (this.chart) {
				this.chart.destroy();
			}
			// 新しいチャートの作成
			this.createChart();
		},
		getChartData() {
			return {
				labels: Object.keys(this.bayesianRatios),
				datasets: [{
					label: '確率 (%)',
					data: Object.values(this.bayesianRatios).map(ratio => ratio * 100),
					backgroundColor: [
						'rgba(255, 99, 132, 0.2)',
						'rgba(54, 162, 235, 0.2)',
						'rgba(255, 206, 86, 0.2)',
						'rgba(75, 192, 192, 0.2)',
						'rgba(153, 102, 255, 0.2)',
						'rgba(255, 159, 64, 0.2)'
					],
					borderColor: [
						'rgba(255, 99, 132, 1)',
						'rgba(54, 162, 235, 1)',
						'rgba(255, 206, 86, 1)',
						'rgba(75, 192, 192, 1)',
						'rgba(153, 102, 255, 1)',
						'rgba(255, 159, 64, 1)'
					],
				borderWidth: 1
				}]
			};
			},
			getChartOptions() {
			// オプションの設定
				return {
					scales: {
						y: {beginAtZero: true}
					},
					plugins: {
						legend: {
							display: true,
							labels: {color: 'rgb(255, 99, 132)'}
						}
					}
				}
			}
	}
};
</script>

<style>
.form-container {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.canvas-container {
  width: 100%;
  height: 400px;
}

canvas {
  width: 100% !important;
  height: 100% !important;
}
</style>