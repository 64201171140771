export default {
	updateTotalSpins(value) {
		this.totalSpins = Number(value);
	},
	updateMidnightBonuses(value) {
		this.midnightBonuses = Number(value);
	},
	updateEpisodeBonuses(value) {
		this.episodeBonuses = Number(value);
	},
	updateCzCount(value) {
		this.czCount = Number(value);
	},
	updateSecondStage(value) {
		// Update the count for secondStageCount based on the event
		this.secondStageCount = Number(value);
	},
	updateATCount(value) {
		this.ATCount = Number(value);
	},
	updatePBCount(value) {
		this.PBCount = Number(value);
	},
	updateMBChecked(value) {
		this.MBChecked = Boolean(value);
	},
	updateEBChecked(value) {
		this.EBChecked = Boolean(value);
	},
	updateCZChecked(value) {
		this.CZChecked = Boolean(value);
	},
	updatePBChecked(value) {
		this.PBChecked = Boolean(value);
	},
	updateSecondStageChecked(value) {
		this.secondStageChecked = Boolean(value);
	},
	handleUpdateSuggestion(payload) {
		this.settingSuggestions[payload.key] = payload.value;
	},
	handleUpdatePayout(payload) {
		this.payouts[payload.key] = payload.value;
	},
	handleUpdateNormalIcon(payload) {
		this.normalIcons[payload.key] = payload.value;
	},
	handleUpdateZeroFiveIcon(payload) {
		this.zeroFiveIcons[payload.key] = payload.value;
	},
	handleUpdateFiftyFiveIcon(payload) {
		this.fiftyFiveIcons[payload.key] = payload.value;
	},
	handleUpdateNinetyNineIcon(payload) {
		this.ninetyNineIcons[payload.key] = payload.value;
	},
	handleUpdateForeseeing(payload) {
		this.foreseeing[payload.key] = payload.value;
	},
}