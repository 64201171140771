<template>
	<div>
		<p>
			もし，カウントし忘れた，もしくは0G以外から始めたため不明である場合はチェックを入れてください．
			AT突入回数についてはボーナス確率に依存するため推定には利用しませんが，引き戻し確率の計算に使用します．
		</p>
		
		<!-- 入力フィールド（総回転数, ミッドナイトボーナス回数, エピソードボーナス回数, CZ） -->
		<div class="spin">
			<label for="totalSpins">総回転数 (通常時)</label>
			<input type="number" id="totalSpins" :value="localTotalSpins" @input="updateTotalSpins($event.target.value)" min="1">
		</div>
  
		<div class="bonus-group">
			<label for="midnightBonuses">ミッドナイトボーナス回数</label>
			<input type="number" id="midnightBonuses" :value="localMidnightBonuses" @input="updateMidnightBonuses($event.target.value)" min="0">
			<span>確率: {{ calculateInverseProbability(localMidnightBonuses, this.localTotalSpins) }}</span>
			<input type="checkbox" id="MBCheckbox" v-model="localMBChecked" @change="updateMBChecked">
		</div>

		<!-- ラベルをボタンのように機能させる，TODO: ボタンを押すと数字は増えるが，ベイズ推定に反映されない
		<div class="bonus-group">
			<button class="label-button" @click="incrementNumber">ミッドナイトボーナス回数</button>
			<input type="number" id="midnightBonuses" :value="localMidnightBonuses" @input="updateMidnightBonuses($event.target.value)" min="0">
			<span>確率: {{ calculateInverseProbability(localMidnightBonuses, this.localTotalSpins) }}</span>
			<input type="checkbox" id="MBCheckbox" v-model="localMBChecked" @change="updateMBChecked">
		</div>
		-->

		<div class="bonus-group">
			<label for="episodeBonuses">エピソードボーナス回数</label>
			<input type="number" id="episodeBonuses" :value="localEpisodeBonuses" @input="updateEpisodeBonuses($event.target.value)" min="0">
			<span>確率: {{ calculateInverseProbability(localEpisodeBonuses, this.localTotalSpins) }}</span>
			<input type="checkbox" id="EBCheckbox" v-model="localEBChecked" @change="updateEBChecked">

		</div>

		<!-- CZ回数の入力フィールド -->
		<div class="bonus-group">
			<label for="czCount">CZ回数</label>
			<input type="number" id="czCount" :value="localCzCount" @input="updateCzCount($event.target.value)" min="0">
			<span>確率: {{ calculateInverseProbability(localCzCount, this.localTotalSpins) }}</span>
			<input type="checkbox" id="CZCheckbox" v-model="localCZChecked" @change="updateCZChecked">
		</div>

		<div>
			<div class="bonus-group">
				<label for="secondStageCount">2ndステージ開始回数※</label>
				<input type="number" id="secondStageCount" :value="localSecondStageCount" @input="updateSecondStage($event.target.value)" min="0">
				<span>確率: {{ calculateInverseProbability(localSecondStageCount, this.localMidnightBonuses) }}</span>
				<input type="checkbox" id="MBCheckbox2" v-model="localMBChecked" @change="updateMBChecked">
			</div>
		</div>

		<div class="bonus-group">
			<label for="ATCount">AT突入回数</label>
			<input type="number" id="ATCount" :value="localATCount" @input="updateATCount($event.target.value)" min="0">
			<span>確率: {{ calculateInverseProbability(localATCount, this.localTotalSpins) }}</span>
			<input type="checkbox" id="PBCheckbox" v-model="localPBChecked" @change="updatePBChecked">
		</div>

		<div class="bonus-group">
			<label for="PBCount">AT引き戻し回数</label>
			<input type="number" id="PBCount" :value="localPBCount" @input="updatePBCount($event.target.value)" min="0">
			<span>確率: {{ calculateInverseProbability(localPBCount, this.localATCount) }}</span>
			<input type="checkbox" id="PBCheckbox2" v-model="localPBChecked" @change="updatePBChecked">
		</div>

		<div class="info-2nd-stage">
			<p>※ミッドナイトボーナスで2ndステージから開始する回数．</p>
		</div>
		<br>
	</div>
</template>

<script>

export default {
	props: {
		totalSpins: Number,
		midnightBonuses: Number,
		episodeBonuses: Number,
		czCount: Number, // CZ回数のプロパティ
		MBChecked: Boolean,
		PBChecked: Boolean,
		EBChecked: Boolean,
		CZChecked: Boolean,
		secondStageChecked: Boolean,
		secondStageCount: Number,
		ATCount: Number,
	},
	data() {
		return {
			localTotalSpins: this.totalSpins,
			localMidnightBonuses: this.midnightBonuses,
			localEpisodeBonuses: this.episodeBonuses,
			localCzCount: this.czCount, // CZ回数のローカルコピー
			localSecondStageCount: this.secondStageCount,
			localATCount: this.ATCount,
			localPBCount: 0,
			localMBChecked: false,
			localPBChecked: false,
			localEBChecked: false,
			localCZChecked: false,
			localSecondStageChecked: false,
		};
	},
	methods: {
		incrementNumber() {
			this.localMidnightBonuses = Number(this.localMidnightBonuses) + 1;
		},
		updateTotalSpins(value) {
			this.localTotalSpins = value;
			this.$emit('update:totalSpins', Number(value));
		},
		updateMidnightBonuses(value) {
			this.localMidnightBonuses = value;
			this.$emit('update:midnightBonuses', Number(value));
		},
		updateEpisodeBonuses(value) {
			this.localEpisodeBonuses = value;
			this.$emit('update:episodeBonuses', Number(value));
		},
		updateCzCount(value) {
			this.localCzCount = Number(value);
			this.$emit('update:czCount', Number(value)); // CZ回数の更新イベントを発火
		},
		updateSecondStage(value) {
			this.localSecondStageCount = value;
			this.$emit('update:secondStageCount', Number(value));
		},
		updatePBCount(value) {
			this.localPBCount = value;
			this.$emit('update:PBCount', Number(value));
		},
		updateATCount(value) {
			this.localATCount = value;
			this.$emit('update:ATCount', Number(value));
		},
		updateMBChecked() {
			this.$emit('update:MBChecked', this.localMBChecked);
		},
		updateEBChecked() {
			this.$emit('update:EBChecked', this.localEBChecked);
		},
		updateCZChecked() {
			this.$emit('update:CZChecked', this.localCZChecked);
		},
		updatePBChecked() {
			this.$emit('update:PBChecked', this.localPBChecked);
		},
		updateSeondStageChecked() {
			this.$emit('update:secondStageChecked', this.localSecondStageChecked);
		},
		calculateInverseProbability(top, bottom) {
			if (top > 0 && bottom > 0) {
				const ratio = bottom / top;
				return `1/${ratio.toFixed(1)}`;
			}
			return '0';
		},
	}
};
</script>
