<template>
	<div>
		<button class="accordion-toggle" :class="{ 'active': isAccordionOpen }" @click="toggleAccordion">
			サイクル55のアイコン出現回数入力
		</button>
		<!--TODO: アコーディオンのゆっくりな開閉アニメーションの追加-->
		<div v-if="isAccordionOpen" class="cycle-form">
			<div class="cycle-form-grid">
				<div v-for="(label, key) in iconLabels" :key="key" class="input-group">
					<label :for="key">{{ label }}</label>
					<input
						type="number"
						:id="key"
						:value="fiftyFiveIcons[key]"
						@input="updateFiftyFiveIcons(key, $event.target.value)"
						min="0"
						max="1"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		fiftyFiveIcons: {
			type: Object,
			default: () => ({})
		}
	},
	data() {
		return {
			iconLabels: {
				blueMoon: "月(青)", greenMoon: "月(緑)", blueDemon: "魔(青)", greenDemon: "魔(緑)", 
				blueHope: "希(青)", greenHope: "希(緑)", whiteLily: "ﾎﾜｲﾄﾘﾘｰ", crestAnemone: "ｸﾚｽﾄｱﾝｽ", 
				snowMoonPlum: "雪月梅花", arcKnightGliss: "ｱｰｸﾅｲﾄｸﾞﾘｽ", violaCazetta: "ｳﾞｨｵﾗｶｯﾂｪ", 
				doroCurtain: "ﾄﾞﾛｾﾗﾉｸﾀｰﾝ", awakeningWhiteLily: "覚醒ﾎﾜｲﾄﾘﾘｰ", 
				seradoCurtain: "ｾﾗﾄﾞｱﾉｸﾀｰﾝ",zeaganLone: "ｼﾞｰｸﾞｧﾝﾛﾝ"
			},
			isAccordionOpen: false // アコーディオンの状態
		};
	},
	methods: {
		updateFiftyFiveIcons(key, value) {
			// 親コンポーネントに変更を通知
			this.$emit('update-FiftyFiveIcon', { key, value: parseFloat(value) });
		},
		toggleAccordion() {
			this.isAccordionOpen = !this.isAccordionOpen;
		}
	}
};
</script>

<style>

.cycle-form {
	margin-bottom: 5px;
	padding: 10px;
}

.cycle-form-grid {
	display: grid;
	grid-template-columns: repeat(2, 1fr); /* 2列に分割 */
	grid-gap: 10px; /* グリッド間の隙間 */
}


</style>