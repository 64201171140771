<template>
	<div class="at-setting-suggestion-form">
		<button class="accordion-toggle" :class="{ 'active': isAccordionOpen }" @click="toggleAccordion">
			AT終了時の設定示唆入力
		</button>
		<div v-if="isAccordionOpen">
			<div class="suggestion-form-grid">
				<div v-for="(label, key) in suggestionLabels" :key="key" class="input-group">
				<label :for="key">{{ label }}</label>
				<input
					type="number"
					:id="key"
					:value="settingSuggestions[key]"
					@input="updateSuggestion(key, $event.target.value)"
					min="0"
					max="100"
				/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		settingSuggestions: {
			type: Object,
			default: () => ({})
		}
	},
	data() {
		return {
			suggestionLabels: {
				weakEven: '偶数設定示唆(弱)',
				weakOdd: '奇数設定示唆(弱)',
				weakHigh: '高設定示唆(弱)',
				mediumEven: '偶数設定示唆(中)',
				mediumOdd: '奇数設定示唆(中)',
				strong246: '設定246濃厚',
				strongEvenHigh: '偶数設定示唆(強)＆高設定示唆(強)',
				strongOddHigh: '奇数設定示唆(強)＆高設定示唆(強)',
				above2: '設定2以上',
				deny3: '設定3否定',
				deny5: '設定5否定',
				above4: '設定4以上',
				above5: '設定5以上',
				confirm6: '設定6確定'
			},
			isAccordionOpen: false // アコーディオンの状態
		};
	},
	methods: {
		updateSuggestion(key, value) {
			// 親コンポーネントに変更を通知
			this.$emit('update-suggestion', { key, value: parseFloat(value) });
		},
		toggleAccordion() {
			this.isAccordionOpen = !this.isAccordionOpen;
		}
	}
};
</script>

<style>

.at-setting-suggestion-form {
	margin-bottom: 5px;
}

.suggestion-form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2列に分割 */
  grid-gap: 10px; /* グリッド間の隙間 */
}
</style>