<template>

  <div class="form-container">

    <h2 class="stylish-title">回胴式遊技機グランベルム</h2>

    <div class="container">
      <div class="image-wrapper">
        <img class="machine-image" src="../../assets/granbelm/machine.png">
      </div>
      <div class="info-wrapper">
        <p>メーカー: 北電子</p>
        <p>機械割: 97.6% 〜 110.9%</p>
        <p>導入開始日: 2023/09/04(月)</p>
      </div>
    </div>

    <p>サーバとの通信はないので一度読み込めばオフラインでご使用いただけます．ページのリロードを行うとデータが消失するため，別でメモを取ることをお勧めします．</p>

    <br>

    <h3 class="fancy-subtitle">ボーナス関連の設定示唆</h3>
    <button class="accordion-toggle" :class="{ 'active': isAccordionOpen }" @click="toggleAccordion">
    ボーナス確率入力
    </button>
    <div v-if="isAccordionOpen">
      <!-- ボーナス確率入力フォーム -->
      <BonusProbabilityForm
        :totalSpins="totalSpins"
        :midnightBonuses="midnightBonuses"
        :episodeBonuses="episodeBonuses"
        :czCount="czCount"
        :secondStageCount="secondStageCount"
        :ATCount="ATCount"
        :PBCount="PBCount"
        :MBChecked="MBChecked"
        :EBChecked="EBChecked"
        :PBChecked="PBChecked"
        :CZChecked="CZChecked"
        :secondStageChecked="secondStageChecked"
        @update:totalSpins="updateTotalSpins"
        @update:midnightBonuses="updateMidnightBonuses"
        @update:episodeBonuses="updateEpisodeBonuses"
        @update:czCount="updateCzCount"
        @update:secondStageCount="updateSecondStage"
        @update:ATCount="updateATCount"
        @update:PBCount="updatePBCount"
        @update:MBChecked="updateMBChecked"
        @update:EBChecked="updateEBChecked"
        @update:CZChecked="updateCZChecked"
        @update:PBChecked="updatePBChecked"
        @update:secondStageChecked="updateSecondStageChecked"
      />
    </div>

    <ATSuggestionForm
      :setting-suggestions="settingSuggestions"
      @update-suggestion="handleUpdateSuggestion"
    />

    <PayoutForm
      :payouts="payouts"
      @update-payout="handleUpdatePayout"
    />

    <h3 class="fancy-subtitle">ナカミミエールモードでの示唆</h3>
    <NormalCycleProbabilityForm
      :normal-icons="normalIcons"
      @update-normalIcon="handleUpdateNormalIcon"
    />

    <Cycle0or5ProbabilityForm
      :zerofive-icons="zeroFiveIcons"
      @update-zeroFiveIcon="handleUpdateZeroFiveIcon"
    />

    <Cycle55ProbabilityForm
      :fiftyfive-Icons="zeroFiveIcons"
      @update-FiftyFiveIcon="handleUpdateFiftyFiveIcon"
    />

    <Cycle99ProbabilityForm
      :ninetynine-Icons="ninetyNineIcons"
      @update-NinetyNineIcon="handleUpdateNinetyNineIcon"
    />

    <ForeseeingForm
      :foreseeing="foreseeing"
      @update-foreseeing="handleUpdateForeseeing"
    />

    <br>

    <!-- 事前確率入力フォーム -->
    <PriorProbabilityForm :priorProbabilities="priorProbabilities" />

    <!-- ベイズ推定を計算するボタン -->
    <div class="calculation-container">
      <button class="calculate-button" @click="calculateBayesianRatios">各設定の確率を計算</button>
      <ul class="results-list">
        <li v-for="(ratio, setting) in bayesianRatios" :key="setting" class="result-item">
          <span class="setting-label">設定{{ setting }}:</span>
          <span class="likelihood-ratio">確率: {{ (ratio * 100).toFixed(2) }}%</span>
          <span v-if="confidenceIntervals[setting]" class="confidence-interval">
            <br>
            (95%信用区間: {{ ((ratio * 100) - confidenceIntervals[setting].lower).toFixed(2) }}%
             - {{ ((ratio * 100) + confidenceIntervals[setting].upper).toFixed(2) }}%)
          </span>
        </li>
      </ul>

      <p class="confidence-interval-explanation">
        ※95%信用区間: データを元に推測した設定の確率が95%で収まる区間
      </p>
    </div>

    <!--低設定，高設定である確率を表示-->
    <div class="probability-display">
      <div class="probability-section">
        <span class="probability-label">低設定(1〜3)である確率: </span>
        <span class="probability-value">{{ (totalRatio1to3 * 100).toFixed(2) }}%</span>
        <!-- TODO
        <div v-if="sumRatiosAndCalculateConfidenceInterval" class="confidence-interval">
          (95%信用区間: {{ ((totalRatio1to3 * 100) - sumRatiosAndCalculateConfidenceInterval(1, 3).lower).toFixed(2) }}%
          - {{ ((totalRatio1to3 * 100) + sumRatiosAndCalculateConfidenceInterval(1, 3).upper).toFixed(2) }}%)
        </div>
        -->
      </div>
    
      <div class="probability-section">
        <span class="probability-label">高設定(4〜6)である確率: </span>
        <span class="probability-value">{{ (totalRatio4to6 * 100).toFixed(2) }}%</span>
        
        <!--
        <div v-if="sumRatiosAndCalculateConfidenceInterval" class="confidence-interval">
          (95%信用区間: {{ ((totalRatio4to6 * 100) - sumRatiosAndCalculateConfidenceInterval(4, 6).lower).toFixed(2) }}%
          - {{ ((totalRatio4to6 * 100) + sumRatiosAndCalculateConfidenceInterval(4, 6).upper).toFixed(2) }}%)
        </div>
        -->
      </div>
    
    </div>
    
    <BarChart ref="barChart" :bayesianRatios="bayesianRatios" />

  </div>
</template>





<script>
import PriorProbabilityForm from './PriorProbabilityForm.vue';
import BonusProbabilityForm from './BonusProbabilityForm.vue';
import ATSuggestionForm from './ATSuggestionForm.vue';
import NormalCycleProbabilityForm from './NormalCycleProbabilityForm.vue';
import Cycle0or5ProbabilityForm from './Cycle0or5ProbabilityForm.vue'
import Cycle55ProbabilityForm from './Cycle55ProbabilityForm.vue'
import Cycle99ProbabilityForm from './Cycle99ProbabilityForm.vue'
import PayoutForm from './PayoutForm.vue'
import ForeseeingForm from './ForeseeingForm.vue'
import BarChart from './BarChart.vue';
import { data } from './data.js';
import updateMethods from '../../methods/granbelm/update.js'
import globalMethods from '../../methods/globalMethods.js'
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default {
  components: {
    PriorProbabilityForm,
    BonusProbabilityForm,
    ATSuggestionForm,
    NormalCycleProbabilityForm,
    Cycle0or5ProbabilityForm,
    Cycle55ProbabilityForm,
    Cycle99ProbabilityForm,
    PayoutForm,
    ForeseeingForm,
    BarChart,
  },
  data() {
    return {
      ...data,
      isAccordionOpen: false // アコーディオンの状態
    };
  },
  computed: {
    // 設定1から3の合計
    totalRatio1to3() {
      return this.sumRatios(1, 3);
    },
    // 設定4から6の合計
    totalRatio4to6() {
      return this.sumRatios(4, 6);
    },
  },
  methods: {
    ...updateMethods,
    ...globalMethods,
    calculateBayesianRatios() {
      let totalBayesianLikelihood = 0;
      const bayesianLikelihoods = {};
      
      for (const [index, prob] of this.priorProbabilities.entries()) {
        const setting = index + 1; // 配列は0から始まるので、1を加算
        const bonus_probs = this.bonusProbabilities[setting.toString()];
        const stage_probs = this.stageProbabilities[setting.toString()];
        const pullback_probs = this.atPullbackRates[setting.toString()];
        const priorProbability = prob.probability / 100; // パーセンテージを割合に変換
        const suggestion_probs = this.settingSuggestionProbabilities[setting.toString()]; // 設定示唆の確率を取得
        const payout_probs = this.payoutProbabilities[setting.toString()]; // 獲得枚数表示の確率を取得
        const foreseeing_probs = this.foreseeingProbabilities[setting.toString()]; // 獲得枚数表示の確率を取得
        const normalCycle_probs = this.cycleNormalProbabilities[setting.toString()]; // 通常サイクル
        const zeroFiveCycle_probs = this.cycleZeroFiveProbabilities[setting.toString()]; // 0/5サイクル
        const fiftyFiveCycle_probs = this.cycleFiftyFiveProbabilities[setting.toString()]; // 55サイクル
        const ninetyNineCycle_probs = this.cycleNinetyNineProbabilities[setting.toString()]; // 99サイクル

        let midnightLikelihood; // 変数をブロック外で宣言
        let secondStageLikelihood;
        if (this.MBChecked) {
          midnightLikelihood = 1;
          secondStageLikelihood = 1;
        } else {
          midnightLikelihood = this.calculateBinomialLikelihood(this.totalSpins, this.midnightBonuses, bonus_probs.midnight);
          secondStageLikelihood = this.calculateBinomialLikelihood(this.midnightBonuses, this.secondStageCount, stage_probs.secondStage);
        }

        // EBの尤度計算
        let episodeLikelihood;
        if (this.EBChecked) {
          episodeLikelihood = 1;
        } else {
          episodeLikelihood = this.calculateBinomialLikelihood(this.totalSpins, this.episodeBonuses, bonus_probs.episode);
        }

        // CZの尤度計算
        let czLikelihood;
        if (this.CZChecked) {
          czLikelihood = 1;
        } else {
          czLikelihood = this.calculateBinomialLikelihood(this.totalSpins, this.czCount, bonus_probs.cz);
        }

        // 引き戻しの尤度計算
        let pullbackLikelihood;
        if (this.PBChecked) {
          pullbackLikelihood = 1;
        } else {
          pullbackLikelihood = this.calculateBinomialLikelihood(this.ATCount, this.PBCount, pullback_probs)
        }
        
        // 設定示唆に基づく尤度計算
        let suggestionLikelihood = 1;
        for (const [suggestionKey, appearNumber] of Object.entries(this.settingSuggestions)) {
          for (let i = 0; i < appearNumber; i++) {
            suggestionLikelihood *= suggestion_probs[suggestionKey] / 100; // 各示唆の確率を掛け合わせる
          }
        }

        //獲得枚数表示
        let payoutLikelihood = 1;
        for (const [icon, appearNumber] of Object.entries(this.payouts)) {
          for (let i = 0; i < appearNumber; i++) {
            payoutLikelihood *= payout_probs[icon] / 100; // 各示唆の確率を掛け合わせる
          }
        }
        //console.log(payoutLikelihood)

        // ナカミミエールモードの示唆に基づく尤度計算
        //通常サイクル
        let normalCycleLikelihood = 1;
        for (const [icon, appearNumber] of Object.entries(this.normalIcons)) {
          for (let i = 0; i < appearNumber; i++) {
            normalCycleLikelihood *= normalCycle_probs[icon] / 100; // 各示唆の確率を掛け合わせる
          }
        }

        // 05サイクル
        let Cycle05Likelihood = 1;
        for (const [icon, appearNumber] of Object.entries(this.zeroFiveIcons)) {
          for (let i = 0; i < appearNumber; i++) {
            Cycle05Likelihood *= zeroFiveCycle_probs[icon] / 100; // 各示唆の確率を掛け合わせる
          }
        }

        // 55サイクル
        let Cycle55Likelihood = 1;
        for (const [icon, appearNumber] of Object.entries(this.fiftyFiveIcons)) {
          for (let i = 0; i < appearNumber; i++) {
            Cycle55Likelihood *= fiftyFiveCycle_probs[icon] / 100; // 各示唆の確率を掛け合わせる
          }
        }

        // 99サイクル
        let Cycle99Likelihood = 1;
        for (const [icon, appearNumber] of Object.entries(this.ninetyNineIcons)) {
          for (let i = 0; i < appearNumber; i++) {
            Cycle99Likelihood *= ninetyNineCycle_probs[icon] / 100; // 各示唆の確率を掛け合わせる
          }
        }

        // 先読みアイテム後
        let foreseeingLikelihood = 1;
        for (const [icon, appearNumber] of Object.entries(this.foreseeing)) {
          console.log(icon)
          for (let i = 0; i < appearNumber; i++) {
            foreseeingLikelihood *= foreseeing_probs[icon] / 100; // 各示唆の確率を掛け合わせる
          }
        }
        
        // 尤度の値を配列に格納
        const likelihoods = [
          midnightLikelihood,
          episodeLikelihood,
          czLikelihood,
          secondStageLikelihood,
          pullbackLikelihood,
          suggestionLikelihood,
          payoutLikelihood,
          normalCycleLikelihood,
          Cycle05Likelihood,
          Cycle55Likelihood,
          Cycle99Likelihood,
          foreseeingLikelihood
        ];

        // reduceメソッドを使って全ての尤度を掛け合わせる
        const likelihood = likelihoods.reduce((total, current) => total * current, 1);
        
        if (!isNaN(likelihood)) {
          bayesianLikelihoods[setting] = likelihood * priorProbability;
          totalBayesianLikelihood += bayesianLikelihoods[setting];
        }
      }

      // 尤度比の計算
      const bayesianRatios = {};
      if (totalBayesianLikelihood > 0) {
        for (const setting in bayesianLikelihoods) {
          bayesianRatios[setting] = bayesianLikelihoods[setting] / totalBayesianLikelihood;
        }
      }

      this.bayesianRatios = bayesianRatios;

      this.$nextTick(() => {
        //console.log(this.$refs.barChart); // デバッグ情報を表示
        if (this.$refs.barChart) {
          this.$refs.barChart.updateChart();
        }
      });
      
      console.log('Bayesian Ratios(app):', JSON.parse(JSON.stringify(this.bayesianRatios)));
      // 信用区間の計算
      this.confidenceIntervals = {};
      for (const [setting, ratio] of Object.entries(this.bayesianRatios)) {
        this.confidenceIntervals[setting] = this.calculateConfidenceInterval(ratio, 5); // ここで自由度を指定
      }
      //console.log(JSON.parse(JSON.stringify(this.confidenceIntervals)));
      
    },
    
    
  }
};
</script>
