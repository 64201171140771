import * as math from 'mathjs';
import { jStat } from 'jstat';

export default {
	toggleAccordion() {
		this.isAccordionOpen = !this.isAccordionOpen;
	},

	calculateBinomialLikelihood(parameter, successes, probability) {
		/*
		if (typeof successes !== 'number' || typeof probability !== 'number') {
			// エラーハンドリング: 適切な型ではない引数が渡された場合
			console.error('Invalid argument type for calculateBinomialLikelihood');
			return 0; // あるいは他の適切なデフォルト値やエラー処理
		}
		*/
		const n = parameter;
		const k = successes;
		const p = probability;

		// 対数を用いた尤度計算
		const logLikelihood = math.log(math.combinations(n, k)) + k * math.log(p) + (n - k) * math.log(1 - p);
		return Math.exp(logLikelihood);
	},
	incrementNumber(n) {
		n = Number(n) + 1;
		return n
	},
	calculateConfidenceInterval(likelihoodRatio, degreeOfFreedom = 5, confidenceLevel = 0.95) {
		// カイ二乗分布の下限と上限のクリティカル値を求めます
		const lowerCriticalValue = jStat.chisquare.inv(1 - confidenceLevel / 2, degreeOfFreedom);
		const upperCriticalValue = jStat.chisquare.inv(confidenceLevel / 2, degreeOfFreedom);

		// 尤度比の信用区間を計算します
		const lowerBound = (likelihoodRatio / upperCriticalValue) * 100; // 100を掛けてパーセンテージ表示に
		const upperBound = (likelihoodRatio / lowerCriticalValue) * 100; // 同様に100を掛ける

		return { lower: lowerBound, upper: upperBound };
	},
	sumRatios(start, end) {
		let total = 0;
		for (let i = start; i <= end; i++) {
			total += this.bayesianRatios[i] || 0;
		}
		return total;
	},

	/* TODO: 低設定，高設定の正確な信用区間の計算
    sumRatiosAndCalculateConfidenceInterval(start, end, degreeOfFreedom = 1, confidenceLevel = 0.95) {
      let totalRatio = 0;
      // 低設定の最大尤度
      for (let i = start; i <= end; i++) {
        totalRatio += this.bayesianRatios[i] || 0;
      }

      const lowerCriticalValue = jStat.chisquare.inv(1 - confidenceLevel / 2, degreeOfFreedom);
      const upperCriticalValue = jStat.chisquare.inv(confidenceLevel / 2, degreeOfFreedom);

      // 尤度比の信用区間を計算します
      const lowerBound = (totalRatio / upperCriticalValue) * 100; // 100を掛けてパーセンテージ表示に
      const upperBound = (totalRatio / lowerCriticalValue) * 100; // 同様に100を掛ける

      return { lower: lowerBound, upper: upperBound };
    },
    */
}