<template>
	<div class="at-setting-payout-form">
		<button class="accordion-toggle" :class="{ 'active': isAccordionOpen }" @click="toggleAccordion">
			獲得枚数表示の入力
		</button>
		<div v-if="isAccordionOpen">
			<div class="payout-form-grid">
				<div v-for="(label, key) in payoutLabels" :key="key" class="input-group">
				<label :for="key">{{ label }}</label>
				<input
					type="number"
					:id="key"
					:value="payouts[key]"
					@input="updatePayout(key, $event.target.value)"
					min="0"
					max="100"
				/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		payouts: {
			type: Object,
			default: () => ({})
		}
	},
	data() {
		return {
			payoutLabels: {
				payout222: "222枚OVER",
				payout333: "333枚OVER",
				payout456: "456枚OVER",
				payout55: "555枚OVER",
				payout666: "666枚OVER"
			},
			isAccordionOpen: false // アコーディオンの状態
		};
	},
	methods: {
		updatePayout(key, value) {
			// 親コンポーネントに変更を通知
			this.$emit('update-payout', { key, value: parseFloat(value) });
		},
		toggleAccordion() {
			this.isAccordionOpen = !this.isAccordionOpen;
		}
	}
};
</script>

<style>

.at-setting-payout-form {
	margin-bottom: 5px;
}

.payout-form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2列に分割 */
  grid-gap: 10px; /* グリッド間の隙間 */
}
</style>